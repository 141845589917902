import React from "react";
import "./Slideshow.css";
import Slide from "./Slide";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { selectAdd } from "../../Actions";

class SlideshowContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { index: -1 };
  }

  componentDidMount() {
    this.setIndexState(1);
    setInterval(() => {
      this.setIndexState();
    }, 8000);
  }
  componentWillUnmount() {
    this.setIndexState = () => {};
  }
  setIndexState(_index = null) {
    //console.log(this.state)
    if (_index && _index < 0) return;
    _index = !_index
      ? this.state.index + 1 <= this.props.slides.length
        ? this.state.index + 1
        : 1
      : _index;
    this.setState({
      index: _index
    });
  }

  generateDots() {
    return this.props.slides.map((item, _index = 0) => {
      _index++;
      return (
        <div
          key={item.id}
          className={"dot " + (_index === this.state.index) ? "active" : ""}
          onClick={() => {
            this.setIndexState(_index);
          }}
        />
      );
    });
  }
  generateSlides() {
    return this.props.slides.map((item, _index = 0) => {
      _index++;
      return (
        <Link
          to={`/anunt/${item.id}`}
          key={item.id}
          onClick={() => {
            this.props.selectAdd(item);
          }}
        >
          <Slide
            display={_index === this.state.index ? "block" : "none"}
            slide={item}
            total={this.props.slides.length}
            index={_index}
          />
        </Link>
      );
    });
  }

  render() {
    return (
      <div className="slideshow-container width100">
        {this.generateSlides()}
        <div className="center">{this.generateDots()}</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    slides: state.adds.filter(
      item => item.slideshow === true && item.deleted === false
    )
  };
};

export default connect(
  mapStateToProps,
  { selectAdd }
)(SlideshowContainer);
