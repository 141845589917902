import React from "react";
import firebaseSDK from "../Settings/FirebaseConfig";

class PageLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = { username: "", password: "" };
  }

  onFormSubmit = (event) => {
    event.preventDefault();
    firebaseSDK.signInWithEmailAndPassword(
      this.state,
      this.signInSuccess,
      console.log
    );
  };

  signInSuccess = (userInfo) => {
    //console.log("signInSuccess", this.props.history);

    setTimeout(() => {
      this.props.history.push("/admin");
    }, 1000);
  };

  render() {
    return (
      <div className="auth-page-main-div">
        <div className="auth-page restrict-width">
          <br />
          <br />
          <div className="header-center">Autentificare</div>

          <form onSubmit={this.onFormSubmit} className="vertical-narrow">
            <div className="label-input-set-vertical">
              <label htmlFor="username">Nume utilizator</label>
              <input
                type="text"
                placeholder="username"
                onChange={(e) => this.setState({ username: e.target.value })}
              />
            </div>
            <div className="label-input-set-vertical">
              <label htmlFor="password">Parola</label>
              <input
                type="password"
                placeholder="password"
                onChange={(e) => this.setState({ password: e.target.value })}
              />
            </div>
            <div className="label-input-set-vertical">
              <label htmlFor="password" />
              <input
                className="bhp-button"
                type="submit"
                value="Autentificare"
              />
            </div>
          </form>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {/*
          <div className="forgot-password vertical-narrow">
            <div className="forgot-pass">Am uitat parola</div>
           <div className="flex-row-centered">
              <div className="">User nou?</div>
              <div className="forgot-pass">Inregitrare user</div>
          </div>
          </div>
 */}
        </div>
      </div>
    );
  }
}

export default PageLogin;
