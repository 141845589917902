import React from "react";
import AddForm from "./AddForm";

class AddEdit extends React.Component {
  render() {
    return (
      <div className="admin-page-home-main-div">
        <div className="admin-page-home restrict-width">
          <h2>Modifica Anunt</h2>
          <AddForm />
        </div>
      </div>
    );
  }
}

export default AddEdit;
