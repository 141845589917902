import React from "react";
import { withRouter } from "react-router-dom";
import CategoryList from "./CategoryList";
import { Link } from "react-router-dom";

const renderCreate = () => {
  return (
    <div className="ui segment">
      <div className="label-input-set-vertical">
        <Link to="/admin/category/new" className="admin-raport-box">
          + Articol Website
        </Link>
      </div>
    </div>
  );
};

const Categories = props => {
  return (
    <div className="admin-page-home-main-div">
      <div className="admin-page-home restrict-width">
        <h2>Lista Articole </h2>
        <CategoryList admin="1" />
        {renderCreate()}
      </div>
    </div>
  );
};

export default withRouter(Categories);
