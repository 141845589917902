import React from "react";
import "./Slideshow.css";

const Slide = props => {
  const noImg =
    "https://firebasestorage.googleapis.com/v0/b/bhp-market.appspot.com/o/content%2Fsquare-image.png?alt=media&token=81e956ff-dc7e-4036-8057-c16721bfeb6d";

  return (
    <div className={`height500px fade ${props.display}`}>
      {/*<div className="numbertext">
        {props.index} / {props.total}
  </div>*/}
      <div
        className="width100 height500px slide-img"
        style={{
          backgroundImage:
            "url('" + (props.slide.img0 ? props.slide.img0 : noImg) + "')"
        }}
        alt={props.slide.title}
      />
      <div className="slideshow-title-container">
        <div className="adjustment-div">&nbsp;</div>
        <div className="slideshow-title">{props.slide.title}</div>
      </div>
    </div>
  );
};
export default Slide;
