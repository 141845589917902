import React from "react";
import { withRouter } from "react-router-dom";
import WebsitePageForm from "./WebsitePageForm";

import { connect } from "react-redux";
import { selectPage } from "../../../Actions/index";

import firebaseSDK from "../../../Settings/FirebaseConfig";

class WebsitePageEdit extends React.Component {
  componentDidMount() {
    const id = this.props.match.params.id;
    //console.log(id);
    firebaseSDK.getPageById(
      id,
      "Pages",
      doc => {
        this.props.selectPage(doc);
        //console.log(doc);
      },
      error => {
        //console.log(error);
        this.props.selectPage({ id: id, title: id, description: id });
      }
    );
  }
  render() {
    if (!this.props.selectedPage) return null;
    return (
      <div className="admin-page-home-main-div">
        <div className="admin-page-home restrict-width">
          <h2>
            Modifica Pagina Website -{" "}
            <span style={{ textTransform: "uppercase" }}>
              {this.props.selectedPage.id}
            </span>
          </h2>
          <WebsitePageForm />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => ({
  selectedPage: state.selectedPage // retrieve name from redux store
});
const mapDispatchToProps = dispatch => ({
  selectPage: page => dispatch(selectPage(page))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WebsitePageEdit));
