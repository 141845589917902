class _Category {
  ImgUploadOnSuccess(result) {
    console.log(result);
  }
  constructor(
    id,
    title,
    description,
    category = "Tuning",
    createdAt,
    img0 = "",
    img1 = "",
    img2 = "",
    img3 = "",
    img4 = "",
    img5 = "",
    img6 = "",
    img7 = "",
    img8 = "",
    img9 = "",
    deleted = false
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.category = category;
    this.createdAt = createdAt;
    this.img0 = img0;
    this.img1 = img1;
    this.img2 = img2;
    this.img3 = img3;
    this.img4 = img4;
    this.img5 = img5;
    this.img6 = img6;
    this.img7 = img7;
    this.img8 = img8;
    this.img9 = img9;
    this.deleted = deleted;
  }
  static returnArray = data => {
    let state = [];
    for (let index in data) {
      const item = data[index].data();
      /* console.log(
        "ITEM: " + index,
        item.createdAt,
        " xxx ",
        item.createdAt && item.createdAt !== undefined
          ? new Date(item.createdAt)
          : null
      ); */
      state.push(
        new _Category(
          item.id,
          item.title,
          item.description,
          item.category,
          item.createdAt,
          item.img0,
          item.img1,
          item.img2,
          item.img3,
          item.img4,
          item.img5,
          item.img6,
          item.img7,
          item.img8,
          item.img9,
          item.deleted
        )
      );
    }
    return state;
  };

  static getFileNameFromFullPath = filePath => {
    if (filePath.indexOf("?") < 0) return null;
    filePath = filePath.split("?")[0];
    if (filePath.indexOf("/") < 0) return null;
    filePath = filePath.split("/");
    filePath = filePath[filePath.length - 1];
    filePath = decodeURIComponent(filePath);
    return filePath;
  };
  static getThumbPath = fullPath => {
    const sP = decodeURIComponent(fullPath).split("/");
    fullPath = "thumb_" + sP[sP.length - 1];
    /*fullPath = fullPath
      .replace(/\?/g, "%3F")
      .replace(/=/g, "%3D")
      .replace(/&/g, "%26");*/
    sP[sP.length - 2] = sP[sP.length - 2] + "%2F" + fullPath;
    sP.splice(sP.length - 1, 1);
    //console.log(sP.join("/"));
    return sP.join("/");
  };
  static returnObjFromArray = (data, id) => {
    const list = this.returnArray(data);
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) return list[i];
    }
    return null;
  };
  static returnPageArray = data => {
    let state = [];
    for (let index in data) {
      const item = data[index].data();
      /* console.log(
        "ITEM: " + index,
        item.createdAt,
        " xxx ",
        item.createdAt && item.createdAt !== undefined
          ? new Date(item.createdAt)
          : null
      ); */
      state.push(item);
    }
    return state;
  };
  static returnPageFromArray = (data, id) => {
    const list = this.returnPageArray(data);
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) return list[i];
    }
    return null;
  };
}
export default _Category;
