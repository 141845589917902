import React from "react";
import phoneSvg from "../Resources/svg/phone.svg";

class PageContact extends React.Component {
  render() {
    return (
      <div className="contact-page-main-div">
        <div className="contact-page restrict-width">
          <div className="contact-map">
            <iframe
              title="unique-iframe-title"
              className="contact-map-item"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2779.553906127112!2d24.04719501586145!3d45.84021257910716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474c69c1817e3043%3A0x816effaaa777a053!2sBhp+Motor+Tuning!5e0!3m2!1sro!2sro!4v1559058011750!5m2!1sro!2sro"
              frameBorder="0"
              allowFullScreen
            />
          </div>

          <div className="contact-form-container">
            <div className="business-info">
              <h2>Bhp Motor Tuning</h2>
              <p>Jud Sibiu, loc. Sura Mica, str. Calea Ocnei nr. 61</p>
              <p>C.I.F.: 40554455</p>
              <p>Nr. Reg. Com.: J32/336/2019</p>
              <p>Capital social: 200.00 RON</p>
              <p>Banca: Banca Comerciala Romana</p>
              <p>IBAN: RO22 RNCB 0857 1725 4377 0001</p>
              <br />
            </div>

            <div className="business-info">
              <b style={{ fontSize: "1.5rem" }}>Contacteaza-ne</b>
              <p style={{ paddingTop: "16px" }}>
                Email:&nbsp;
                <a
                  href="mailto:bhpmotortuning@gmail.com"
                  style={{ color: "#fe7f34" }}
                >
                  bhpmotortuning@gmail.com
                </a>
              </p>
              <div
                className="header-middle-item"
                style={{ paddingBottom: "10px" }}
              >
                <a
                  href="tel:0040799768298"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "4px",
                  }}
                >
                  <img src={phoneSvg} alt="phone" />
                  <span className="phone-number">0040 799 768 298</span>
                </a>
              </div>
              <div className="media-icons">
                <a
                  href="https://www.facebook.com/mircea.chira"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fa fa-facebook"
                >
                  &nbsp;
                </a>
                <a
                  href="https://wa.me/40799768298"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fa fa-whatsapp"
                >
                  &nbsp;
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageContact;
