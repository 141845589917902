import React from "react";
import { Link } from "react-router-dom";
import AddList from "../../AddList";

class AdminAddList extends React.Component {
  componentDidMount() {
    //if (!this.props.adds) console.log("MUST FETCH ADDS");
  }
  renderAdmin(stream) {
    if (stream.userId === this.props.currentUserId) {
      return (
        <div className="right floated content">
          <Link
            to={`/admin/add/edit/${stream.id}`}
            className="ui button primary"
          >
            Edit
          </Link>
          <Link
            to={`/admin/add/delete/${stream.id}`}
            className="ui button negative"
          >
            Delete
          </Link>
        </div>
      );
    }
  }
  renderList() {
    if (this.props.adds) {
      return this.props.adds.map(stream => {
        return (
          <div className="item" key={stream.id}>
            {this.renderAdmin(stream)}
            <i className="large middle aligned icon camera" />
            <div className="content">
              <Link className="header" to={`/admin/add/edit/${stream.id}`}>
                {stream.title}
              </Link>
              <div className="description">{stream.description}</div>
            </div>
          </div>
        );
      });
    } else {
      return "no List";
    }
  }
  renderCreate() {
    return (
      <div className="ui segment">
        <div className="label-input-set-vertical">
          <Link to="/admin/add/new" className="admin-raport-box">
            + Anunt
          </Link>
        </div>
      </div>
    );
  }
  render() {
    //console.log(this.props.streams);
    return (
      <div className="admin-page-home-main-div">
        <div className="admin-page-home restrict-width">
          <h2>Lista Anunturi</h2>
          <AddList admin="1" />
          {this.renderCreate()}
        </div>
      </div>
    );
  }
}

export default AdminAddList;
