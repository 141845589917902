import React from "react";
import { Link } from "react-router-dom";

class AdminAddList extends React.Component {
  render() {
    //console.log(this.props.streams);
    return (
      <div className="admin-page-home-main-div">
        <div className="admin-page-home restrict-width">
          <h2>Pagini Website Prezentare</h2>
          <ul>
            <li>
              <Link to="/admin/website-pages/dsAiPZgPW0i570oPPrFu">
                <h2 style={{ color: "orange" }}>Tuning</h2>
              </Link>
            </li>

            <li>
              <Link to="/admin/website-pages/bBNScnLKHiVviEPP94Pp">
                <h2 style={{ color: "orange" }}>Airbag</h2>
              </Link>
            </li>

            <li>
              <Link to="/admin/website-pages/qnkglyYajMBP9ktuas9S">
                <h2 style={{ color: "orange" }}>Chei</h2>
              </Link>
            </li>

            <li>
              <Link to="/admin/website-pages/9kDlNw8RKljFAw1C5iIm">
                <h2 style={{ color: "orange" }}>Alte Servicii</h2>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default AdminAddList;
