//updateCategory , selectCategory
export const updateCategory = add => {
  return {
    type: "UPDATE_LIST_CATEGORY",
    payload: add
  };
};
export const removeCategory = id => {
  return {
    type: "REMOVE_LIST_CATEGORY",
    payload: id
  };
};
export const selectCategory = add => {
  return {
    type: "CATEGORY_SELECTED",
    payload: add
  };
};
export const overwriteListCategories = adds => {
  return {
    type: "OVERWRITE_LIST_CATEGORIES",
    payload: adds
  };
};
