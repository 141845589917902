import React from "react";
import "../App.css";
import { NavLink } from "react-router-dom";

class Header extends React.Component {
  openSliderMenu = (event) => {
    const _el = document.getElementsByClassName("slider-menu")[0];
    if (_el) {
      _el.classList.remove("hidden");
      _el.classList.remove("slider-menu-slide-out");
      _el.classList.add("slider-menu-slide-in");
    }
  };
  closeSliderMenu = (event) => {
    const _el = document.getElementsByClassName("slider-menu")[0];
    if (_el) {
      _el.classList.remove("slider-menu-slide-in");
      _el.classList.add("slider-menu-slide-out");
      setTimeout(function () {
        _el.classList.add("hidden");
      }, 200);
    }
  };
  render() {
    return (
      <div className="header-section-main-div">
        <div className="slider-menu hidden" onClick={this.closeSliderMenu}>
          <NavLink
            to="/"
            exact
            className="slide-menu-item"
            onClick={this.closeSliderMenu}
          >
            Home
          </NavLink>
          <NavLink
            to="/autoturisme"
            className="slide-menu-item"
            onClick={this.closeSliderMenu}
          >
            Autoturisme
          </NavLink>
          <NavLink
            to="/autoutilitare"
            className="slide-menu-item"
            onClick={this.closeSliderMenu}
          >
            Autoutilitare
          </NavLink>
          <NavLink
            to="/contact"
            className="slide-menu-item no-bottom-border"
            onClick={this.closeSliderMenu}
          >
            Contact
          </NavLink>
        </div>

        <header>
          <div className="menu-flex-row restrict-width">
            <div
              className="menu-item menu-mini item-left"
              onClick={this.openSliderMenu}
            >
              <div className="mini-bars" />
              <div className="mini-bars" />
              <div className="mini-bars" />
            </div>
            <div className="header-logo">
              <NavLink to="/" exact className="noHover">
                <img
                  className="header-logo-website"
                  alt="Vanzari BHP Motor"
                  src="https://firebasestorage.googleapis.com/v0/b/bhp-market.appspot.com/o/content%2Flogo_180x70.png?alt=media&token=57a3f667-8cde-4953-b0dd-97799f0f79e7"
                />
              </NavLink>
            </div>
            <div className="header-welcome-msg hidden">
              <NavLink to="/" exact className="noHover">
                <img
                  className="header-logo-website"
                  alt="Vanzari BHP Motor"
                  src="https://firebasestorage.googleapis.com/v0/b/bhp-market.appspot.com/o/content%2Flogo_180x70.png?alt=media&token=57a3f667-8cde-4953-b0dd-97799f0f79e7"
                />
              </NavLink>
            </div>
            <div className="menu-flex-row menu-display">
              <NavLink to="/autoturisme" className="menu-item item-left">
                Autoturisme
              </NavLink>
              <NavLink to="/autoutilitare" className="menu-item">
                Autoutilitare
              </NavLink>
              <NavLink to="/contact" className="menu-item item-right">
                Contact
              </NavLink>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Header;
