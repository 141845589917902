import React from "react";
import { connect } from "react-redux";
import { selectCategory } from "../../../Actions/category";
import { Link } from "react-router-dom";

const Category = ({ item, admin, classAddon }) => {
  const _classes = ["add-list-view"];
  _classes.push(classAddon);

  const _date = new Date(item.createdAt).toLocaleString().toString();
  //console.log(_date);
  //console.log(this.getShortDateFormat(item.createdAt));

  if (!item) return "no Items";
  const noImg =
    "https://firebasestorage.googleapis.com/v0/b/bhp-market.appspot.com/o/content%2Fsquare-image.png?alt=media&token=81e956ff-dc7e-4036-8057-c16721bfeb6d";
  return (
    <div className={_classes.join(" ")}>
      <span className="add-title">
        {item.title}
        <i style={{ color: "red" }}>
          {item.deleted ? " - Anunt Inactiv -" : ""}
        </i>
      </span>
      <div className="add-row">
        <div className="add-image-box">
          <img
            className="img-thumb"
            src={item.img0 ? item.img0 : noImg}
            alt={item.title}
          />
        </div>
        <div className="add-details-box">
          <div className="add-price-box">
            <div>
              <small>{admin ? "Data Anunt: " + _date : ""}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

class CategoryList extends React.Component {
  render() {
    const isAdmin = this.props.admin;
    let _index = 0;
    const categories = !isAdmin
      ? this.props.categories.filter(function (item) {
          return item.deleted === false;
        })
      : this.props.categories;
    return (
      <div className="adds-main restrict-width">
        {categories.map((item) => {
          _index++;
          let classAddon =
            _index % 2 ? "lighter-background-color" : "darker-background-color";
          classAddon += item.isNew ? " badge-new-car" : "";

          return (
            <Link
              className="auth-page-main-div" //{`/streams/edit/${stream.id}`}
              to={!isAdmin ? `/category/${item.id}` : "/admin/category/edit"}
              key={item.id}
              onClick={() => {
                this.props.selectCategory(item);
              }}
            >
              <Category
                item={item}
                classAddon={classAddon}
                admin={this.props.admin}
              />
            </Link>
          );
        })}
      </div>
    );
  }
}

// (state) = all REDUX STORE
const mapStateToProps = (state, props) => {
  //filterset autoturism/autoutilitar
  let _adds = [...state.categories];
  //console.log(state.adds);
  if (props.category) {
    _adds = [];
    for (let x = 0, _add; x < state.adds.length; x++) {
      _add = state.adds[x];
      if (_add.category === props.category) _adds.push(_add);
    }
  }
  //console.log(state.adds);

  //console.log("ADD LIST:", state);
  return { categories: _adds };
  // this.props === {adds: [...]}
};

export default connect(
  mapStateToProps,
  { selectCategory } //{selectCategory:selectCategory}
)(CategoryList);
