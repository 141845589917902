import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { setAuthReducer } from "./AuthReducer";
import {
  addReducer,
  selectedAddReducer,
  selectedPageReducer
} from "./AddReducer";
import { categoryReducer, selectedCategoryReducer } from "./CategoryReducer";

export default combineReducers({
  adds: addReducer,
  selectedAdd: selectedAddReducer,
  auth: setAuthReducer,
  form: formReducer,
  selectedPage: selectedPageReducer,
  categories: categoryReducer,
  selectedCategory: selectedCategoryReducer
});
