import React from "react";
import AddForm from "./AddForm";
import { selectAdd } from "../../../Actions";
import { connect } from "react-redux";
import _Add from "../../../Models/_Add";

class AddCreate extends React.Component {
  componentWillMount() {
    this.props.selectAdd(new _Add());
  }
  render() {
    return (
      <div className="admin-page-home-main-div">
        <div className="admin-page-home restrict-width">
          <h2>Adauga Anunt</h2>
          <AddForm />
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { selectAdd } //{selectAdd:selectAdd}
)(AddCreate);
