import React from "react";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import AddList from "./Components/AddList";
import Contact from "./Components/Contact";
import Login from "./Components/Login";
import PrivateRoute from "./Components/PrivateRoute";

import AdminHome from "./Components/Admin/AdminHome";
import AddCreate from "./Components/Admin/Adds/AddCreate";
import AddEdit from "./Components/Admin/Adds/AddEdit";
import AddDelete from "./Components/Admin/Adds/AddDelete";
import AdminAddList from "./Components/Admin/Adds/Adds";
import AdminWebsitePagesList from "./Components/Admin/WebsitePages/AdminWebsitePagesList";
import { Route, BrowserRouter } from "react-router-dom";

//Categories
import CategoryCreate from "./Components/Admin/Category/CategoryCreate";
import CategoryEdit from "./Components/Admin/Category/CategoryEdit";
import CategoryDelete from "./Components/Admin/Category/CategoryDelete";
import Categories from "./Components/Admin/Category/Categories";

import firebaseSDK from "./Settings/FirebaseConfig";
import PageLabel from "./Components/PageLabel";
import Slideshow from "./Components/Slideshow/Slideshow";
import AddDetails from "./Components/AddDetails";
import AdminHeader from "./Components/Admin/AdminHeader";
import WebsitePageEdit from "./Components/Admin/WebsitePages/WebsitePageEdit";

import { connect } from "react-redux";
import { setAuth, overwriteListAdds, selectAdd } from "./Actions";
import { overwriteListCategories } from "./Actions/category";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      adds: [
        {
          id: 0,
          title: "",
          decription: "",
        },
      ],
      errorMessage: "",
    };
  }

  errorDisplay = (errorMessage) => {
    //set state.errorMesage to App.js
    this.setState({ errorMessage: errorMessage });
  };

  componentDidMount() {
    //connect to a collection + getAll items
    firebaseSDK.getAll("Add", this.props.overwriteListAdds, this.errorDisplay);
    firebaseSDK.getAll(
      "Categories",
      this.props.overwriteListCategories,
      this.errorDisplay
    );
    firebaseSDK.onAuthStateChanged(this.props.setAuth, console.log);
    //console.log("INIT APP");
  }

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Route path="/" component={Header} />

          <Route
            path="/"
            exact
            render={() => {
              return (
                <div className="home-page-main-div">
                  <Slideshow />
                  <div className="adds-header">
                    <div className="restrict-width">
                      <PageLabel label1="Vanzari " label2="BHP MOTOR" />
                    </div>
                  </div>
                  <div className="adds-main restrict-width">
                    <AddList />
                  </div>
                </div>
              );
            }}
          />
          <Route
            path="/anunt/:id"
            exact
            render={(props) => {
              return (
                <div className="home-page-main-div">
                  <div className="adds-main restrict-width">
                    <AddDetails
                      {...props}
                      selectAdd={this.props.selectAdd}
                      add={this.props.add}
                    />
                  </div>
                </div>
              );
            }}
          />
          <Route
            path="/autoturisme"
            exact
            render={() => {
              return (
                <div className="home-page-main-div">
                  <Slideshow />
                  <div className="adds-header">
                    <div className="restrict-width">
                      <PageLabel label1="Autoturisme" label2="" />
                    </div>
                  </div>
                  <div className="adds-main restrict-width">
                    <AddList category="autoturism" />
                  </div>
                </div>
              );
            }}
          />
          <Route
            path="/autoutilitare"
            exact
            render={() => {
              return (
                <div className="home-page-main-div">
                  <Slideshow />
                  <div className="adds-header">
                    <div className="restrict-width">
                      <PageLabel label1="Autoutilitare" label2="" />
                    </div>
                  </div>
                  <div className="adds-main restrict-width">
                    <AddList category="autoutilitar" />
                  </div>
                </div>
              );
            }}
          />
          <Route path="/contact" exact component={Contact} />
          <Route path="/login" exact component={Login} />

          {/* ADMIN ROUTES: 
          -------------------------------------------------*/}
          <PrivateRoute path="/admin" component={AdminHeader} />
          <PrivateRoute
            path="/admin/website-pages"
            exact
            component={AdminWebsitePagesList}
          />
          <PrivateRoute
            path="/admin/website-pages/:id"
            exact
            component={WebsitePageEdit}
          />
          <PrivateRoute path="/admin" component={AdminHome} exact />
          <PrivateRoute path="/admin/adds" exact component={AdminAddList} />
          {/* <PrivateRoute
            path="/admin/settings"
            exact
            component={AdminSettings}
          /> */}
          <PrivateRoute path="/admin/add/new" exact component={AddCreate} />
          <PrivateRoute path="/admin/add/edit" exact component={AddEdit} />
          <PrivateRoute
            path="/admin/add/delete/:id"
            exact
            component={AddDelete}
          />

          <PrivateRoute path="/admin/categories" exact component={Categories} />
          <PrivateRoute
            path="/admin/category/new"
            exact
            component={CategoryCreate}
          />
          <PrivateRoute
            path="/admin/category/edit"
            exact
            component={CategoryEdit}
          />
          <PrivateRoute
            path="/admin/category/delete/:id"
            exact
            component={CategoryDelete}
          />

          <Route path="/" component={Footer} />
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return { add: state.selectedAdd };
};
const mapDispatchToProps = (dispatch) => ({
  setAuth: (page) => dispatch(setAuth(page)),
  overwriteListAdds: (page) => dispatch(overwriteListAdds(page)),
  overwriteListCategories: (page) => dispatch(overwriteListCategories(page)),
  selectAdd: (page) => dispatch(selectAdd(page)),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
