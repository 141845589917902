import React from "react";
//import $ from "jquery";
import "../App.css";
import { NavLink } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer-section-main-div">
        <div className="footer-body restrict-width">
          <div className="footer-column">
            <div className="footer-column-element bold">
              Anunturi Categorii Masini
            </div>
            <div className="footer-column-element">
              <NavLink to="/" exact className="footer-column-element">
                Toate Anunturile
              </NavLink>
            </div>
            <div className="footer-column-element">
              <NavLink
                to="/autoturisme"
                exact
                className="footer-column-element"
              >
                Autoturisme
              </NavLink>
            </div>
            <div className="footer-column-element">
              <NavLink
                to="/autoutilitare"
                exact
                className="footer-column-element"
              >
                Autoutilitare
              </NavLink>
            </div>
          </div>
          <div className="footer-column last-column">
            <div className="footer-column-element">
              <NavLink to="/contact" exact className="footer-column-element">
                Contact
              </NavLink>
            </div>
            <div className="footer-column-element">
              <NavLink to="/admin" exact className="footer-column-element">
                Administrare
              </NavLink>
            </div>
            <div className="footer-column-element">
              <NavLink
                to="/termeneconditii"
                exact
                className="footer-column-element"
              >
                Termene & Conditii
              </NavLink>
            </div>
          </div>
        </div>
        <div
          className="footer-body restrict-width footer-column-element"
          style={{ justifyContent: "center" }}
        >
          <span>
            Copyright 2019 - {new Date().getFullYear()} BHP Motor Tuning
          </span>
        </div>
      </div>
    );
  }
}

export default Footer;
