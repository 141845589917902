import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";

function Success_Route({ component: Component, ...rest }) {
  return <Route {...rest} render={() => <Component {...rest} />} />;
}
function Failed_Route({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )}
    />
  );
}

function PrivateRoute({ component: Component, ...rest }) {
  //console.log("Private Route!!!", { ...rest }.isAuthenticated);
  let Success_Object = Success_Route({ component: Component, ...rest });
  let Failed_Object = Failed_Route({ component: Component, ...rest });
  return { ...rest }.isAuthenticated ? Success_Object : Failed_Object;
}

const mapStateToPropst = state => {
  return {
    isAuthenticated: state.auth != null
  };
};

export default connect(mapStateToPropst)(PrivateRoute);
