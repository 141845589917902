import React from "react";
class PageLabel extends React.Component {
  render() {
    return (
      <div className="adds-header-label">
        <span>{this.props.label1}</span>
        <span className="bhp-name">{this.props.label2}</span>
        <span>{this.props.label3}</span>
      </div>
    );
  }
}
export default PageLabel;
