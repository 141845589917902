import React from "react";
import ImageViewer from "./ImageViewer";
import firebaseSDK from "../Settings/FirebaseConfig";
import _Add from "../Models/_Add";
import MetaTags from "react-meta-tags";

class AddDetails extends React.Component {
  componentDidMount() {
    if (!this.props.add) {
      firebaseSDK.getById(
        this.props.match.params.id,
        "Add",
        this.props.selectAdd,
        this.errorDisplay
      );
    }
  }
  render() {
    //const props = this.props;
    //console.log(this.props);
    const { add } = this.props;
    if (!add) return null;

    let i = 0;

    //const _date = new Date(add.createdAt).toLocaleString().toString();

    //images array
    //-----------------------------------------------
    const noImg =
      "https://firebasestorage.googleapis.com/v0/b/bhp-market.appspot.com/o/content%2Fsquare-image.png?alt=media&token=81e956ff-dc7e-4036-8057-c16721bfeb6d";
    let images = [];
    for (let i = 0, img; i < 20; i++) {
      img = add["img" + i];
      if (img !== "") {
        images.push({
          original: img,
          //originalClass: "img-thumb-mini-gallery",
          thumbnail: _Add.getThumbPath(img),
          thumbnailClass: "img-thumb-mini-gallery-thumb",
          //sizes: "10px", //[1900, 1080],
        });
      }
    }
    if (images.length === 0)
      images.push({
        original: noImg,
        //originalClass: "img-thumb-mini-gallery",
        thumbnail: noImg,
        thumbnailClass: "img-thumb-mini-gallery-thumb",
      });
    //-----------------------------------------------
    const meta = {
      description:
        "BHP MotorTuning | " +
        add.title +
        " | " +
        add.price +
        " " +
        add.currency,
      title: "BHP MotorTuning | " + add.title,
      url: "https://vanzari.bhpmotortuning.com/anunt/" + add.id,
      image: add.img0,
    };
    //console.log(add.aux);
    return (
      <div className="add-details-main-div restrict-width">
        <MetaTags>
          <title>{meta.title}</title>
          <meta
            name="description"
            property="description"
            content={meta.desription}
          />
          <meta itemProp="name" content={meta.title} />
          <meta itemProp="description" content={meta.description} />
          <meta itemProp="image" content={meta.image} />

          <meta property="og:url" content={meta.url} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={meta.title} />
          <meta property="og:description" content={meta.description} />
          <meta property="og:image" content={meta.image} />
          <meta property="og:locale" content="ro_RO" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={meta.title} />
          <meta name="twitter:description" content={meta.description} />
          <meta name="twitter:image" content={meta.image} />
        </MetaTags>
        <span className="add-title">
          {add.title}
          <i style={{ color: "red" }}>
            {add.deleted ? " - Anunt Inactiv -" : ""}
          </i>
        </span>
        <div className="add-main-section">
          <div className="add-image-box img-thumb-mini-gallery">
            <ImageViewer
              images={images}
              showNav={true}
              showPlayButton={false}
              defaultImage={noImg}
              additionalClass="img-thumb-mini-gallery"
            />
          </div>

          <div className="add-row full-width padding-top-1em">
            <div className="add-column">
              {add.body ? (
                <div>
                  {add.body}
                  <br />
                </div>
              ) : null}

              {add.buildYear ? (
                <div>Prima inregistrare: {add.buildYear}</div>
              ) : null}
              {add.mileage ? <div>{add.mileage}</div> : null}
              {add.fuelType ? <div>{add.fuelType}</div> : null}
              <div>
                {add.kW ? `${add.kW}kW ` : null}{" "}
                {add.hp ? `(${add.hp}hp)` : null}
              </div>
              {add.drive ? (
                <div>
                  {add.drive}
                  <br />
                </div>
              ) : null}
              {add.used ? <div>{add.used}</div> : null}
            </div>
            <div className="add-column">
              <div className="add-price line-height-1em">
                {add.price} {add.currency}
              </div>
              <div className="add-price-tva">
                {add.tva ? "TVA inclus" : "fara TVA"}
              </div>
            </div>
          </div>
        </div>

        <h2 className="line-height-1v5em">Date tehnice</h2>
        <div className="add-row half-width min-width-300px">
          <div className="add-column">
            Capacitate Motor
            <br />
            Numar Scaune
            <br />
            Numar Usi
            <br />
            Categorie Emisii (Euro)
            <br />
            Numar de Proprietari
            <br />
            An Fabricatie
            <br />
            Aer Conditionat
            <br />
            Ajutor Parcare
            <br />
            Culoare
            <br />
            Interior
          </div>
          <div className="add-column">
            {add.capacity}
            <br />
            {add.noSeats}
            <br />
            {add.noDoors}
            <br />
            {add.emission}
            <br />
            {add.noOwners}
            <br />
            {add.buildYear}
            <br />
            {add.ac}
            <br />
            {add.parkAid}
            <br />
            {add.color}
            <br />
            {add.interior}
          </div>
        </div>

        <div className="add-row full-width">
          <div className="add-column">
            <h2 className="padding-top-1em">Dotari</h2>
            <div className="dotari-custom-list">
              {add.aux
                ? JSON.parse(add.aux).map((stream) => {
                    i++;
                    return (
                      <div className="dotari-custom-item" key={i}>
                        <i className="check circle outline icon"></i>{" "}
                        <label>
                          {stream} <br />
                        </label>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>

        <div className="add-row full-width">
          <div className="add-column">
            <h2 className="padding-top-1em">Descriere</h2>
            <div>
              {add.description
                ? add.description.split(/\n/).map((stream) => {
                    i++;
                    return (
                      <label key={i}>
                        {stream} <br />
                      </label>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddDetails;
