import React from "react";
import firebaseSDK from "../Settings/FirebaseConfig";
import uuid from "uuid";

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { percentage: 0 };
    this.MustHave_PostFileToStorage_OnSuccess = this.props.MustHave_PostFileToStorage_OnSuccess;
  }

  processFile = event => {
    let file = event.target.files[0];
    //console.log(file);

    if (file) {
      //let data = new FormData();
      //data.append("file", file);
      //console.log(data);
      const _uid = uuid();
      firebaseSDK.postStorage(
        {
          file: file,
          folderName: "Uploads",
          fileName: _uid + "-" + file.name
        },
        this.progressBar,
        this.completeFile,
        null
      );
    }
  };

  progressBar = percentage => {
    this.setState({ percentage });
  };

  completeFile = result => {
    this.MustHave_PostFileToStorage_OnSuccess(result);
  };

  render() {
    return (
      <div className="admin-img-thumb-div">
        <h2 className="img-thumb">{this.state.percentage} %</h2>
        <input
          id="upload"
          ref="upload"
          type="file"
          accept="image/*"
          onInput={event => {
            this.processFile(event);
          }}
          onClick={event => {
            event.target.value = null;
          }}
        />
      </div>
    );
  }
}
export default FileUpload;
