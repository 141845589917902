import React from "react";
import CategoryForm from "./CategoryForm";

const CategoryEdit = () => (
  <div className="admin-page-home-main-div">
    <div className="admin-page-home restrict-width">
      <h2>Modifica Articol Website</h2>
      <CategoryForm />
    </div>
  </div>
);

export default CategoryEdit;
