import React from "react";
import "../../App.css";
import { NavLink } from "react-router-dom";
import firebaseSDK from "../../Settings/FirebaseConfig";
import { connect } from "react-redux";

class AdminHeader extends React.Component {
  constructor() {
    super();
    this.state = { user: {} };
  }
  openSliderMenu = (event) => {
    const _el = document.getElementsByClassName("slider-menu")[0];
    if (_el) {
      _el.classList.remove("hidden");
      _el.classList.remove("slider-menu-slide-out");
      _el.classList.add("slider-menu-slide-in");
    }
  };
  closeSliderMenu = (event) => {
    const _el = document.getElementsByClassName("slider-menu")[0];
    if (_el) {
      _el.classList.remove("slider-menu-slide-in");
      _el.classList.add("slider-menu-slide-out");
      setTimeout(function () {
        _el.classList.add("hidden");
      }, 200);
    }
  };
  render() {
    return (
      <div className="header-section-main-div">
        <div className="slider-menu hidden">
          <NavLink
            to="/admin"
            exact
            className="slide-menu-item"
            onClick={this.closeSliderMenu}
          >
            Dashboard
          </NavLink>
          <NavLink
            to="/admin/categories"
            className="slide-menu-item"
            onClick={this.closeSliderMenu}
          >
            Articole Website
          </NavLink>
          <NavLink
            to="/admin/adds"
            className="slide-menu-item"
            onClick={this.closeSliderMenu}
          >
            Anunturi
          </NavLink>
          {/* <NavLink
            to="/admin/settings"
            className="slide-menu-item"
            onClick={this.closeSliderMenu}
          >
            Setari
          </NavLink> */}
          <NavLink
            to="/"
            exact
            className="slide-menu-item no-bottom-border"
            onClick={this.closeSliderMenu}
          >
            Logout
          </NavLink>
        </div>

        <header className="hide-on-mobile">
          <div className="admin-header restrict-width">
            <div className="menu-flex-row menu-display">
              <NavLink to="/admin" exact className="menu-item item-left">
                Dashboard
              </NavLink>
              {/* <NavLink to="/admin/website-pages" className="menu-item">
                Pagini Website
              </NavLink> */}
              <NavLink to="/admin/categories" className="menu-item">
                Articole Website
              </NavLink>
              <NavLink to="/admin/adds" className="menu-item">
                Anunturi
              </NavLink>
              {/* <NavLink to="/admin/settings" className="menu-item">
                Setari
              </NavLink> */}
              <NavLink
                onClick={firebaseSDK.signOut}
                to="/"
                exact
                className="menu-item item-right"
              >
                Logout
              </NavLink>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log(state);
  return { user: state.auth };
};
export default connect(mapStateToProps)(AdminHeader);
