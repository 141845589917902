import React from "react";
import { Field, reduxForm } from "redux-form";
import firebaseSDK from "../../../Settings/FirebaseConfig";
import uuid from "uuid";
import { connect } from "react-redux";
import { updateListAdd, selectAdd, removeListAdd } from "../../../Actions";
import { Redirect } from "react-router-dom";
import FileUpload from "../../FileUpload";
import _Add from "../../../Models/_Add";

class AddForm extends React.Component {
  /*componentWillMount() {
    this.initialValues = this.props.loadSelectedAdd();
  }*/
  state = {
    redirectToNewPage: false,
  };
  maxPictures = 20;
  renderError(meta) {
    if (meta.touched && meta.error) {
      return (
        <div className="ui error message">
          <div className="header">{meta.error}</div>
        </div>
      );
    }
  }
  getSelectedStateValue(propName) {
    return { ...this.props.initialValues }[propName];
  }
  setSelectedStateWithNewValue(propName, propValue) {
    let updatedAdd = { ...this.props.initialValues };
    updatedAdd[propName] = propValue;
    this.props.selectAdd(updatedAdd);
    console.log("setSelectedStateWithNewValue", updatedAdd);
  }
  renderInput = (formProps) => {
    //console.log(formProps);
    const className = `field ${
      formProps.meta.error && formProps.meta.touched ? "error" : ""
    }`;
    return (
      <div className={className}>
        <label>{formProps.label}</label>
        <input
          ref={(c) => (this[formProps.input.name] = c)}
          {...formProps.input}
          autoComplete="off"
          onChange={() => {
            this.setSelectedStateWithNewValue(
              formProps.input.name,
              this[formProps.input.name].value
            );
          }}
        />
        {this.renderError({ ...formProps.meta })}
      </div>
    );
  };
  renderTextarea = (formProps) => {
    const className = `field ${
      formProps.meta.error && formProps.meta.touched ? "error" : ""
    }`;
    return (
      <div className={className}>
        <label>{formProps.label}</label>
        <textarea
          rows="25"
          cols="40"
          {...formProps.input}
          autoComplete="off"
          ref={(c) => (this[formProps.input.name] = c)}
          onChange={() => {
            this.setSelectedStateWithNewValue(
              formProps.input.name,
              this[formProps.input.name].value
            );
          }}
        />
        {this.renderError({ ...formProps.meta })}
      </div>
    );
  };
  renderCheckbox = (formProps) => {
    const className = `field ${
      formProps.meta.error && formProps.meta.touched ? "error" : ""
    }`;
    //console.log(formProps);
    return (
      <div className={className}>
        <label>{formProps.label}</label>
        <input
          {...formProps.input}
          autoComplete="off"
          type="checkbox"
          checked={!formProps.input.value ? false : formProps.input.value}
          ref={(c) => (this[formProps.input.name] = c)}
          onChange={() => {
            this.setSelectedStateWithNewValue(
              formProps.input.name,
              this[formProps.input.name].checked
            );
          }}
        />
        {this.renderError({ ...formProps.meta })}
      </div>
    );
  };
  renderImg = (formProps) => {
    return (
      <div className="field img-thumb margin1em">
        <label
          className={
            formProps.input.value.length > 0
              ? "row-spacebetween"
              : "row-spacebetween hidden-important"
          }
        >
          {formProps.label}
          <i
            className="trash icon color-red"
            onClick={() => {
              this.ImgDeleteOnSuccess(formProps.index);
            }}
          />
        </label>

        <input
          className="hidden"
          {...formProps.input}
          autoComplete="off"
          disabled
          ref={(c) => (this[formProps.input.name] = c)}
          onChange={() => {
            this.setSelectedStateWithNewValue(
              formProps.input.name,
              this[formProps.input.name].value
            );
          }}
        />
        <img
          className="field img-thumb"
          src={formProps.input.value}
          alt={formProps.input.value}
        />
      </div>
    );
  };
  renderOptions = (list) => {
    return list.map((item) => {
      return (
        <option className="field" key={item.value} value={item.value}>
          {item.text}
        </option>
      );
    });
  };
  renderSelect = (formProps) => {
    const className = `field ${
      formProps.meta.error && formProps.meta.touched ? "error" : ""
    }`;
    return (
      <div className={className}>
        <label>{formProps.label}</label>
        <select
          className="field"
          {...formProps.input}
          autoComplete="off"
          ref={(c) => (this[formProps.input.name] = c)}
          onChange={() => {
            this.setSelectedStateWithNewValue(
              formProps.input.name,
              this[formProps.input.name].value
            );
          }}
        >
          {this.renderOptions(formProps.options)}
        </select>
        {this.renderError({ ...formProps.meta })}
      </div>
    );
  };
  renderDynamicListAdd = (formProps) => {
    return (
      <div className="columns padding-bottom-30px">
        <div className="column-cols">
          <input
            ref={(input) => (this.customTextInput = input)}
            autoComplete="off"
          />
        </div>
        <div className="column-cols">
          <i
            className="plus square icon font-size-2v5em"
            onClick={() => {
              //formProps.input.value
              let _newArray = JSON.parse(formProps.input.value);
              _newArray.push(this.customTextInput.value);
              this.customTextInput.value = "";
              //console.log(this.customTextInput.value);
              let updatedAdd = { ...this.props.initialValues };
              updatedAdd.aux = JSON.stringify(_newArray);
              this.props.selectAdd(updatedAdd);
              //console.log(formProps);
            }}
          />
        </div>
      </div>
    );
  };
  renderDynamicListItems = (formProps) => {
    if (formProps.input && formProps.input.value) {
      const _aux = JSON.parse(formProps.input.value);
      let rows = [];
      for (let x = 0; x < _aux.length; x++) {
        rows.push(
          <div className="columns" key={x}>
            <div className="column-cols lista-dotari-text">{_aux[x]}</div>
            <div className="column-cols">
              <i
                className="trash icon lista-dotari-icon"
                onClick={() => {
                  let _newArray = JSON.parse(formProps.input.value);
                  _newArray.splice(x, 1);
                  let updatedAdd = { ...this.props.initialValues };
                  updatedAdd.aux = JSON.stringify(_newArray);
                  this.props.selectAdd(updatedAdd);
                }}
              />
            </div>
          </div>
        );
      }

      return <div>{rows}</div>;
    }
  };
  renderDynamicList = (formProps) => {
    const className = `field ${
      formProps.meta.error && formProps.meta.touched ? "error" : ""
    }`;
    return (
      <div className={className}>
        <label>{formProps.label}</label>
        {this.renderDynamicListAdd(formProps)}
        {this.renderDynamicListItems(formProps)}
        {this.renderError({ ...formProps.meta })}
      </div>
    );
  };
  onSubmit = (formValues) => {
    //const init = { ...formValues };
    let values = { ...this.props.initialValues };
    if (!values.id) {
      values.id = uuid();
      values.createdAt = Date.parse(new Date());
    }
    console.log("SUBMIT", this.props.initialValues, formValues);
    //return;
    firebaseSDK.set("Add", values, this.onSubmitSuccess, console.log);
  };
  onSubmitSuccess = (doc) => {
    this.props.updateListAdd(doc);
    this.setState({ redirectToNewPage: true });
  };
  onDelete = (formValues) => {
    //console.log(formValues, this.props.initialValues);
    formValues = { ...this.props.initialValues };
    if (formValues.id) {
      //delete firebase
      firebaseSDK.delete(
        "Add",
        formValues,
        () => {
          //remove from redux add
          this.props.removeListAdd(formValues.id);
        },
        console.log
      );

      //delete all images
      for (let i = 0, filePath; i < this.maxPictures; i++) {
        filePath = formValues["img" + i];
        //console.log(filePath, i, "DELETE");
        if (filePath && filePath.length > 0) {
          //delete firebase references
          this.ImgFirebaseDelete(_Add.getFileNameFromFullPath(filePath));
        }
      }
    }
    this.setState({ redirectToNewPage: true });
  };
  render() {
    if (this.state.redirectToNewPage) {
      return <Redirect to="/admin/adds" />;
    }
    const pictures = [];
    for (let index = 0; index < this.maxPictures; index++) {
      pictures.push(
        <Field
          key={index}
          label="Imagine"
          name={`img${index}`}
          index={index}
          component={this.renderImg}
        />
      );
    }
    const showUpload =
      this.props.initialValues[`img${this.maxPictures - 1}`].length === 0;
    return (
      <div>
        <form
          className="ui form error"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Field label="Titlu" name="title" component={this.renderInput} />
          <div className="columns padding-bottom-30px">
            <div className="column-cols">
              <Field label="Pret" name="price" component={this.renderInput} />
              <Field
                label="Moneda"
                name="currency"
                component={this.renderSelect}
                options={[
                  { value: null, text: "-" },
                  { value: "EUR", text: "EUR" },
                  { value: "RON", text: "RON" },
                ]}
              />
              <Field
                label="Categorie"
                name="category"
                component={this.renderSelect}
                options={[
                  { value: null, text: "-" },
                  { value: "autoturism", text: "Autoturism" },
                  { value: "autoutilitar", text: "Autoutilitar" },
                ]}
              />
              <Field
                label="Oferta Slideshow"
                name="slideshow"
                component={this.renderCheckbox}
              />
              <Field
                label="Caroserie"
                name="body"
                component={this.renderInput}
              />
              <Field
                label="Aer Conditionat"
                name="ac"
                component={this.renderInput}
              />
              <Field
                label="An Fabricatie"
                name="buildYear"
                component={this.renderInput}
              />
              <Field
                label="Transmisie"
                name="drive"
                component={this.renderInput}
              />
            </div>
            <div className="column-cols">
              <Field
                label="Combustibil"
                name="fuelType"
                component={this.renderInput}
              />
              <Field label="Putere KW" name="kW" component={this.renderInput} />
              <Field label="Putere HP" name="hp" component={this.renderInput} />
              <Field
                label="Kilometri"
                name="mileage"
                component={this.renderInput}
              />
              <Field label="Stare" name="used" component={this.renderInput} />
              <Field
                label="TVA Inclus"
                name="tva"
                component={this.renderCheckbox}
              />
              <Field
                label="Anunt Nou"
                name="isNew"
                component={this.renderCheckbox}
              />
              <Field
                label="Anunt Inactiv"
                name="deleted"
                component={this.renderCheckbox}
              />
            </div>
          </div>
          <h2>Date tehnice</h2>
          <div className="columns padding-bottom-30px">
            <div className="column-cols">
              <Field
                label="Capacitate Motor"
                name="capacity"
                component={this.renderInput}
              />
              <Field
                label="Numar Scaune"
                name="noSeats"
                component={this.renderInput}
              />
              <Field
                label="Numar Usi"
                name="noDoors"
                component={this.renderInput}
              />
              <Field
                label="Categorie Emisii"
                name="emission"
                component={this.renderInput}
              />
            </div>
            <div className="column-cols">
              <Field
                label="Numar Proprietari Anteriori"
                name="noOwners"
                component={this.renderInput}
              />
              <Field
                label="Ajutor Parcare"
                name="parkAid"
                component={this.renderInput}
              />
              <Field
                label="Culoare"
                name="color"
                component={this.renderInput}
              />
              <Field
                label="Interior"
                name="interior"
                component={this.renderInput}
              />
            </div>
          </div>
          <h2>Dotari</h2>
          <Field
            label="Lista Dotari"
            name="aux"
            component={this.renderDynamicList}
          />
          <h2>Descriere</h2>
          <Field
            label="Descriere"
            name="description"
            component={this.renderTextarea}
          />
          <div className="admin-img-thumb-div">{pictures}</div>
          {showUpload ? (
            <FileUpload
              MustHave_PostFileToStorage_OnSuccess={this.ImgUploadOnSuccess}
            />
          ) : null}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "16px",
            }}
          >
            <div className="label-input-set-vertical">
              <button className="bhp-button">Submit</button>
            </div>
            <div className="label-input-set-vertical">
              <button
                className="bhp-button"
                onClick={(event) => {
                  event.stopPropagation();
                  this.onDelete();
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </form>
        <br />
      </div>
    );
  }
  ImgUploadOnSuccess = (result) => {
    //console.log("RESULT", result);
    let updatedAdd = { ...this.props.initialValues };
    for (let i = 0; i < this.maxPictures; i++) {
      if (updatedAdd["img" + i] === "") {
        updatedAdd["img" + i] = result;
        break;
      }
    }
    this.props.selectAdd(updatedAdd);
  };
  ImgFirebaseDelete = (filePath) => {
    //delete firebase file + thumbnail;
    firebaseSDK.deleteStorage(
      filePath,
      () => {
        console.log("Success deleted: ", filePath);
      },
      console.log
    );
    //delete thumbnail also
    filePath = decodeURIComponent(_Add.getThumbPath(filePath));
    firebaseSDK.deleteStorage(
      filePath,
      () => {
        console.log("Success deleted: ", filePath);
      },
      console.log
    );
  };
  ImgDeleteOnSuccess = (index) => {
    //delete firebase references
    let filePath = this.getSelectedStateValue("img" + index);
    this.ImgFirebaseDelete(_Add.getFileNameFromFullPath(filePath));

    console.log("initialdelete", this.props.initialValues, index);
    let updatedAdd = { ...this.props.initialValues };
    updatedAdd["img" + index] = "";
    let bFound = false;
    for (let i = index; i < this.maxPictures; i++) {
      console.log("BEFORE", i, updatedAdd["img" + i], bFound);
      if (updatedAdd["img" + i].length === 0) bFound = true;

      if (bFound) {
        if (i + 1 < this.maxPictures) {
          updatedAdd["img" + i] = updatedAdd["img" + (i + 1)];
        } else {
          updatedAdd["img" + i] = "";
        }
      }
      console.log("AFTER", i, updatedAdd["img" + i], bFound);
    }
    console.log("ImgDeleteOnSuccess.END", this.props.initialValues, updatedAdd);
    this.props.selectAdd(updatedAdd);
  };
}

const validateForm = (formValues) => {
  const errors = {};

  if (!formValues.title) errors.title = "Introduceti titlu";
  if (!formValues.description) errors.description = "Introduceti descriere";
  if (!formValues.price) errors.price = "Introduceti pret";
  if (!formValues.currency) errors.currency = "Introduceti moneda";
  if (!formValues.category) errors.category = "Introduceti categorie";
  //if (!formValues.tva) errors.tva = "Introduceti TVA";
  return errors;
};

const mapStateToProps = (state, props) => ({
  initialValues: state.selectedAdd, // retrieve name from redux store
});
const mapDispatchToProps = (dispatch) => ({
  updateListAdd: (page) => dispatch(updateListAdd(page)),
  selectAdd: (page) => dispatch(selectAdd(page)),
  removeListAdd: (page) => dispatch(removeListAdd(page)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "addNew",
    validate: validateForm,
    enableReinitialize: true,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    forceUnregisterOnUnmount: true,
  })(AddForm)
);
