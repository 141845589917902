import React from "react";
import { Link } from "react-router-dom";
import AmCharts from "@amcharts/amcharts3-react";
import { connect } from "react-redux";

class AdminHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dataProvider: this.generateData(), timer: null };
  }

  componentDidMount() {
    this.setState({
      dataProvider: this.generateData(),
    });
    /*
    this.setState({
      // Update the chart dataProvider every 3 seconds
      timer: setInterval(() => {
        this.setState({
          dataProvider: this.generateData()
        });
      }, 30000)
    });
    */
  }

  componentWillUnmount() {
    /*
    clearInterval(this.state.timer);
    */
  }

  isDate = function (date) {
    return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
  };
  getShortDateFormat = (date) => {
    const _date = new Date(date);
    const _day = _date.getDate();
    const _month = _date.getMonth() + 1;
    const _year = _date.getFullYear();
    return _month + "/" + _day + "/" + _year;
  };
  getDataProvider = (list) => {
    let ret = {};
    for (let i = 0, dt; i < list.length; i++) {
      dt = this.getShortDateFormat(list[i].createdAt);
      if (ret[dt] != null) {
        ret[dt] = ret[dt] + 1;
      } else {
        ret[dt] = 1;
      }
    }
    return ret;
  };
  // Generate random data
  generateData = () => {
    var dataProvider = [];

    const adds = this.props.adds;
    const list = this.getDataProvider(adds);
    const listKeys = Object.keys(list);

    for (let i = 0; i < listKeys.length; ++i) {
      dataProvider.push({
        date: listKeys[i],
        value: list[listKeys[i]],
      });
    }

    return dataProvider;
  };

  render() {
    //console.log(this.props);
    const config = {
      type: "serial",
      theme: "light",
      marginRight: 40,
      marginLeft: 40,
      autoMarginOffset: 20,
      mouseWheelZoomEnabled: true,
      valueAxes: [
        {
          id: "v1",
          axisAlpha: 0,
          position: "left",
          ignoreAxisWidth: true,
        },
      ],
      balloon: {
        borderThickness: 1,
        shadowAlpha: 0,
      },
      graphs: [
        {
          id: "g1",
          balloon: {
            drop: true,
            adjustBorderColor: false,
            color: "#ffffff",
          },
          bullet: "square",
          bulletBorderAlpha: 1,
          bulletColor: "#FFFFFF",
          bulletSize: 5,
          hideBulletsCount: 50,
          lineThickness: 2,
          title: "red line",
          useLineColorForBulletBorder: true,
          valueField: "value",
          balloonText: "<span style='font-size:18px;'>[[value]]</span>",
        },
      ],
      chartScrollbar: {
        graph: "g1",
        oppositeAxis: false,
        offset: 30,
        scrollbarHeight: 80,
        backgroundAlpha: 0,
        selectedBackgroundAlpha: 0.1,
        selectedBackgroundColor: "#888888",
        graphFillAlpha: 0,
        graphLineAlpha: 0.5,
        selectedGraphFillAlpha: 0,
        selectedGraphLineAlpha: 1,
        autoGridCount: true,
        color: "#AAAAAA",
      },
      chartCursor: {
        pan: true,
        valueLineEnabled: true,
        valueLineBalloonEnabled: true,
        cursorAlpha: 1,
        cursorColor: "#258cbb",
        limitToGraph: "g1",
        valueLineAlpha: 0.2,
        valueZoomable: true,
      },
      valueScrollbar: {
        oppositeAxis: false,
        offset: 50,
        scrollbarHeight: 10,
      },
      categoryField: "date",
      categoryAxis: {
        parseDates: true,
        dashLength: 1,
        minorGridEnabled: true,
      },
      dataProvider: this.state.dataProvider,
    };

    return (
      <div className="admin-page-home-main-div">
        <div className="admin-page-home restrict-width">
          <div className="ui segment">
            <Link to="/admin/add/new" className="admin-raport-box">
              + Anunt
            </Link>
            &nbsp;&nbsp;
            <Link to="/admin/category/new" className="admin-raport-box">
              + Articol Website
            </Link>
          </div>
          <div className="ui segment">
            <div className="ui segment">
              <AmCharts.React
                style={{ width: "100%", height: "400px" }}
                options={config}
              />
            </div>
            <p>Grafic numar total de anunturi adaugate / luna.</p>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  //console.log(state);
  return { adds: state.adds };
};
export default connect(mapStateToProps)(AdminHome);
