import React from "react";
import AddPopup from "../../Popups/AddPopup";
import { Link } from "react-router-dom";

class StreamDelete extends React.Component {
  componentDidMount() {
    this.props.fetchStream(this.props.match.params.id);
  }

  render() {
    const { id } = this.props.match.params;

    const actions = (
      <React.Fragment>
        <button
          className="ui negative button"
          onClick={() => this.props.deleteStream(id)}
        >
          Delete
        </button>
        <Link to="/" className="ui button">
          Cancel
        </Link>
      </React.Fragment>
    );

    return (
      <AddPopup
        title="Delete Stream"
        content={`Are you sure you want to delete this stream ${
          !this.props.stream ? "" : "with title: " + this.props.stream.title
        }?`}
        actions={actions}
        // onDismiss={() => history.push("/")}
      />
    );
  }

  // alternative to render content if based
  // renderContent() {
  //   if (!this.props.stream) {
  //     return "Are you sure ";
  //   }
  // }
}

export default StreamDelete;
