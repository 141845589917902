import React from "react";
import CategoryForm from "./CategoryForm";
import { selectCategory } from "../../../Actions/category";
import { connect } from "react-redux";
import _Category from "../../../Models/_Category";

class CategoryCreate extends React.Component {
  componentDidMount() {
    this.props.selectCategory(new _Category());
  }
  render() {
    return (
      <div className="admin-page-home-main-div">
        <div className="admin-page-home restrict-width">
          <h2>Adauga Articol</h2>
          <CategoryForm />
        </div>
      </div>
    );
  }
}

export default connect(null, { selectCategory })(CategoryCreate);
