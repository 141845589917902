import React from "react";
import { Field, reduxForm } from "redux-form";
import firebaseSDK from "../../../Settings/FirebaseConfig";
import { connect } from "react-redux";
import { selectPage } from "../../../Actions";
import { Redirect } from "react-router-dom";
import FileUpload from "../../FileUpload";
import _Add from "../../../Models/_Add";

class AddForm extends React.Component {
  /*componentWillMount() {
    this.initialValues = this.props.loadSelectedAdd();
  }*/
  state = {
    redirectToNewPage: false,
  };
  renderError(meta) {
    if (meta.touched && meta.error) {
      return (
        <div className="ui error message">
          <div className="header">{meta.error}</div>
        </div>
      );
    }
  }
  getSelectedStateValue(propName) {
    return { ...this.props.initialValues }[propName];
  }
  setSelectedStateWithNewValue(propName, propValue) {
    let updatedAdd = { ...this.props.initialValues };
    updatedAdd[propName] = propValue;
    this.props.selectPage(updatedAdd);
    //console.log(updatedAdd);
  }
  renderInput = (formProps) => {
    //console.log(formProps);
    const className = `field ${
      formProps.meta.error && formProps.meta.touched ? "error" : ""
    }`;
    return (
      <div className={className}>
        <label>{formProps.label}</label>
        <input
          ref={(c) => (this[formProps.input.name] = c)}
          {...formProps.input}
          autoComplete="off"
          onChange={() => {
            this.setSelectedStateWithNewValue(
              formProps.input.name,
              this[formProps.input.name].value
            );
          }}
        />
        {this.renderError({ ...formProps.meta })}
      </div>
    );
  };
  renderTextarea = (formProps) => {
    const className = `field ${
      formProps.meta.error && formProps.meta.touched ? "error" : ""
    }`;
    return (
      <div className={className}>
        <label>{formProps.label}</label>
        <textarea
          rows="25"
          cols="40"
          {...formProps.input}
          autoComplete="off"
          ref={(c) => (this[formProps.input.name] = c)}
          onChange={() => {
            this.setSelectedStateWithNewValue(
              formProps.input.name,
              this[formProps.input.name].value
            );
          }}
        />
        {this.renderError({ ...formProps.meta })}
      </div>
    );
  };
  renderCheckbox = (formProps) => {
    const className = `field ${
      formProps.meta.error && formProps.meta.touched ? "error" : ""
    }`;
    //console.log(formProps);
    return (
      <div className={className}>
        <label>{formProps.label}</label>
        <input
          {...formProps.input}
          autoComplete="off"
          type="checkbox"
          checked={!formProps.input.value ? false : formProps.input.value}
          ref={(c) => (this[formProps.input.name] = c)}
          onChange={() => {
            this.setSelectedStateWithNewValue(
              formProps.input.name,
              this[formProps.input.name].checked
            );
          }}
        />
        {this.renderError({ ...formProps.meta })}
      </div>
    );
  };
  renderImg = (formProps) => {
    //console.log(formProps);
    return (
      <div className="field img-thumb margin1em">
        <label
          className={
            formProps.input.value.length > 0
              ? "row-spacebetween"
              : "row-spacebetween hidden-important"
          }
        >
          {formProps.label}
          <i
            className="trash icon color-red"
            onClick={() => {
              //console.log(this.props.initialValues);

              //set new state redux=>refresh form
              this.ImgDeleteOnSuccess(formProps.index);
              //console.log(this.props.initialValues);
            }}
          />
        </label>

        <input
          className="hidden"
          {...formProps.input}
          autoComplete="off"
          disabled
          ref={(c) => (this[formProps.input.name] = c)}
          onChange={() => {
            this.setSelectedStateWithNewValue(
              formProps.input.name,
              this[formProps.input.name].value
            );
          }}
        />
        <img
          className="field img-thumb"
          src={formProps.input.value}
          alt={formProps.input.value}
        />
      </div>
    );
  };
  renderOptions = (list) => {
    return list.map((item) => {
      return (
        <option className="field" key={item.value} value={item.value}>
          {item.text}
        </option>
      );
    });
  };
  renderSelect = (formProps) => {
    const className = `field ${
      formProps.meta.error && formProps.meta.touched ? "error" : ""
    }`;
    return (
      <div className={className}>
        <label>{formProps.label}</label>
        <select
          className="field"
          {...formProps.input}
          autoComplete="off"
          ref={(c) => (this[formProps.input.name] = c)}
          onChange={() => {
            this.setSelectedStateWithNewValue(
              formProps.input.name,
              this[formProps.input.name].value
            );
          }}
        >
          {this.renderOptions(formProps.options)}
        </select>
        {this.renderError({ ...formProps.meta })}
      </div>
    );
  };
  renderDynamicListAdd = (formProps) => {
    return (
      <div className="columns padding-bottom-30px">
        <div className="column-cols">
          <input
            ref={(input) => (this.customTextInput = input)}
            autoComplete="off"
          />
        </div>
        <div className="column-cols">
          <i
            className="plus square icon font-size-2v5em"
            onClick={() => {
              //formProps.input.value
              let _newArray = JSON.parse(formProps.input.value);
              _newArray.push(this.customTextInput.value);
              this.customTextInput.value = "";
              //console.log(this.customTextInput.value);
              let updatedAdd = { ...this.props.initialValues };
              updatedAdd.aux = JSON.stringify(_newArray);
              this.props.selectPage(updatedAdd);
              //console.log(formProps);
            }}
          />
        </div>
      </div>
    );
  };
  renderDynamicListItems = (formProps) => {
    if (formProps.input && formProps.input.value) {
      const _aux = JSON.parse(formProps.input.value);
      let rows = [];
      for (let x = 0; x < _aux.length; x++) {
        rows.push(
          <div className="columns" key={x}>
            <div className="column-cols lista-dotari-text">{_aux[x]}</div>
            <div className="column-cols">
              <i
                className="trash icon lista-dotari-icon"
                onClick={() => {
                  let _newArray = JSON.parse(formProps.input.value);
                  _newArray.splice(x, 1);
                  let updatedAdd = { ...this.props.initialValues };
                  updatedAdd.aux = JSON.stringify(_newArray);
                  this.props.selectPage(updatedAdd);
                }}
              />
            </div>
          </div>
        );
      }

      return <div>{rows}</div>;
    }
  };
  renderDynamicList = (formProps) => {
    const className = `field ${
      formProps.meta.error && formProps.meta.touched ? "error" : ""
    }`;
    return (
      <div className={className}>
        <label>{formProps.label}</label>
        {this.renderDynamicListAdd(formProps)}
        {this.renderDynamicListItems(formProps)}
        {this.renderError({ ...formProps.meta })}
      </div>
    );
  };
  onSubmit = (formValues) => {
    //const init = { ...formValues };
    console.log(formValues, this.props.initialValues);

    const values = { ...this.props.initialValues };
    console.log("SUBMIT ", values);
    // return;
    firebaseSDK.set("Pages", values, this.onSubmitSuccess, console.log);
  };
  onSubmitSuccess = (doc) => {
    this.props.selectPage(null);
    this.setState({ redirectToNewPage: true });
  };

  render() {
    if (this.state.redirectToNewPage) {
      return <Redirect to="/admin/website-pages" />;
    }
    console.log("RENDER", this.props.initialValues);
    return (
      <div>
        <form
          className="ui form error"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Field label="Titlu" name="title" component={this.renderInput} />
          {/* <Field label="id" name="id" component={this.renderInput} /> */}

          <h2>Descriere</h2>
          <Field
            label="Descriere"
            name="description"
            component={this.renderTextarea}
          />
          <div className="admin-img-thumb-div">
            <Field
              label="Imagine"
              name="img0"
              index={0}
              component={this.renderImg}
            />
            <Field
              label="Imagine"
              name="img1"
              index={1}
              component={this.renderImg}
            />
            <Field
              label="Imagine"
              name="img2"
              index={2}
              component={this.renderImg}
            />
            <Field
              label="Imagine"
              name="img3"
              index={3}
              component={this.renderImg}
            />
            <Field
              label="Imagine"
              name="img4"
              index={4}
              component={this.renderImg}
            />
            <Field
              label="Imagine"
              name="img5"
              index={5}
              component={this.renderImg}
            />
            <Field
              label="Imagine"
              name="img6"
              index={6}
              component={this.renderImg}
            />
            <Field
              label="Imagine"
              name="img7"
              index={7}
              component={this.renderImg}
            />
            <Field
              label="Imagine"
              name="img8"
              index={8}
              component={this.renderImg}
            />
            <Field
              label="Imagine"
              name="img9"
              index={9}
              component={this.renderImg}
            />
          </div>
          <FileUpload
            MustHave_PostFileToStorage_OnSuccess={this.ImgUploadOnSuccess}
          />
          <div className="label-input-set-vertical">
            <button className="bhp-button">Submit</button>
          </div>
        </form>
      </div>
    );
  }
  ImgUploadOnSuccess = (result) => {
    console.log("RESULT", result);
    let updatedAdd = { ...this.props.initialValues };
    for (let i = 0; i < 10; i++) {
      console.log("FOR", i, updatedAdd["img" + i], result);
      if (updatedAdd["img" + i] === "") {
        updatedAdd["img" + i] = result;
        break;
      }
    }
    console.log("RESUTL UPDATED", updatedAdd);
    this.props.selectPage(updatedAdd);
    //firebaseSDK.set("Pages", updatedAdd, console.log, console.log);
  };
  ImgFirebaseDelete = (filePath) => {
    //delete firebase file + thumbnail;
    firebaseSDK.deleteStorage(
      filePath,
      () => {
        console.log("Success deleted: ", filePath);
      },
      console.log
    );
    //delete thumbnail also
    filePath = decodeURIComponent(_Add.getThumbPath(filePath));
    firebaseSDK.deleteStorage(
      filePath,
      () => {
        console.log("Success deleted: ", filePath);
      },
      console.log
    );
  };
  ImgDeleteOnSuccess = (index) => {
    //delete firebase references
    let filePath = this.getSelectedStateValue("img" + index);
    this.ImgFirebaseDelete(_Add.getFileNameFromFullPath(filePath));

    //console.log("initialdelete", this.props.initialValues);
    let updatedAdd = { ...this.props.initialValues };
    updatedAdd["img" + index] = "";
    let bFound = false;
    for (let i = index; i < 10; i++) {
      //console.log("BEFORE", i, updatedAdd["img" + i], bFound);
      if (updatedAdd["img" + i] === "") bFound = true;

      if (bFound) {
        if (i + 1 < 10) {
          updatedAdd["img" + i] = updatedAdd["img" + (i + 1)];
        } else {
          updatedAdd["img" + i] = "";
        }
      }
      //console.log("AFTER", i, updatedAdd["img" + i], bFound);
    }
    this.props.selectPage(updatedAdd);
    //firebaseSDK.set("Pages", updatedAdd, console.log, console.log);
    //console.log("afterinitialdelete", this.props.initialValues);
  };
}

const validateForm = (formValues) => {
  const errors = {};

  if (!formValues.title) errors.title = "Introduceti titlu";
  if (!formValues.description) errors.description = "Introduceti descriere";
  return errors;
};

const mapStateToProps = (state) => ({
  initialValues: state.selectedPage, // retrieve name from redux store
});
const mapDispatchToProps = (dispatch) => ({
  selectPage: (page) => dispatch(selectPage(page)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "addNew",
    validate: validateForm,
    enableReinitialize: true,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    forceUnregisterOnUnmount: true,
  })(AddForm)
);
