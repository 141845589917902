import React from "react";

class Add extends React.Component {
  render() {
    //console.log(this);
    const _classes = ["add-list-view"];
    _classes.push(this.props.classAddon);
    const item = this.props.add;

    const _date = new Date(item.createdAt).toLocaleString().toString();
    //console.log(_date);
    //console.log(this.getShortDateFormat(item.createdAt));

    if (!item) return "no Items";
    const noImg =
      "https://firebasestorage.googleapis.com/v0/b/bhp-market.appspot.com/o/content%2Fsquare-image.png?alt=media&token=81e956ff-dc7e-4036-8057-c16721bfeb6d";
    return (
      <div className={_classes.join(" ")}>
        <span className="add-title">
          {item.title}
          <i style={{ color: "red" }}>
            {item.deleted ? " - Anunt Inactiv -" : ""}
          </i>
        </span>
        <div className="add-row">
          <div className="add-image-box">
            <img
              className="img-thumb"
              src={item.img0 ? item.img0 : noImg}
              alt={item.title}
            />
          </div>
          <div className="add-details-box">
            <div className="row-column">
              <div className="details-column">
                <div>{item.body}</div>
                <div>{item.used}</div>
              </div>
              <div className="details-column">
                <div>{item.buildYear}</div>
                <div>{item.mileage}</div>
                <div>
                  {item.kW}kW / {item.hp}hp
                </div>
              </div>
              <div className="details-column">
                <div>{item.fuelType}</div>
                <div>{item.drive}</div>
                <div>{item.ac}</div>
              </div>
            </div>
            <div className="add-price-box">
              <div className="add-price">
                {item.price} {item.currency}
              </div>
              <div>{item.tva ? "TVA inclus" : "fara TVA"}</div>
              <div>
                <small>{this.props.admin ? "Data Anunt: " + _date : ""}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Add;
