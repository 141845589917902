import React from "react";
import ImageGallery from "react-image-gallery";
import "~../../react-image-gallery/styles/css/image-gallery.css";

const ImageViewer = ({ images, showNav, showPlayButton }) => (
  <ImageGallery
    disableThumbnailScroll={false}
    items={images}
    showNav={showNav}
    showPlayButton={showPlayButton}
    showBullets={false}
    showIndex={false}
    showThumbnails={true}
    onScreenChange={(isFullScreen) => {
      // console.log("FULLSCREEEN", isFullScreen, this);
      let thumbnails = document.getElementsByClassName(
        "image-gallery-thumbnails-wrapper"
      )[0];

      if (isFullScreen) {
        thumbnails.classList.add("hidden-important");
      } else {
        thumbnails.classList.remove("hidden-important");
      }
    }}
  />
);

export default ImageViewer;
