import React from "react";
import { connect } from "react-redux";
import { selectAdd } from "../Actions";
import { Link } from "react-router-dom";
import Add from "./Add";

class AddList extends React.Component {
  render() {
    //console.log(this.props);
    const isAdmin = this.props.admin;
    let _index = 0;
    let _adds = [];
    //console.log(this.props.adds, this.props.category);
    if (this.props.category) {
      _adds = [];
      for (let x = 0, _add; x < this.props.adds.length; x++) {
        _add = this.props.adds[x];
        if (_add.category === this.props.category) _adds.push(_add);
      }
    } else {
      _adds = [...this.props.adds];
    }
    const adds = !isAdmin
      ? _adds.filter(function (item) {
          return item.deleted === false;
        })
      : _adds;
    return (
      <div className="adds-main restrict-width">
        {adds.map((item) => {
          _index++;
          let classAddon =
            _index % 2 ? "lighter-background-color" : "darker-background-color";
          classAddon += item.isNew ? " badge-new-car" : "";

          return (
            <Link
              className="auth-page-main-div" //{`/streams/edit/${stream.id}`}
              to={!isAdmin ? `/anunt/${item.id}` : "/admin/add/edit"}
              key={item.id}
              onClick={() => {
                this.props.selectAdd(item);
              }}
            >
              <Add
                add={item}
                classAddon={classAddon}
                admin={this.props.admin}
              />
            </Link>
          );
        })}
      </div>
    );
  }
}

// (state) = all REDUX STORE
const mapStateToProps = (state, props) => {
  return { adds: state.adds };
};

export default connect(
  mapStateToProps,
  { selectAdd } //{selectAdd:selectAdd}
)(AddList);
